var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "SectionTenantForm-wrapper" },
    [_c("section-form", _vm._b({}, "section-form", _vm.params, false))],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }